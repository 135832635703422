import "./cusListing.scss";
import moment from "moment";
import {useState} from "react";
import { makeRequest } from "../../../axios";
import { MoreVertRounded } from "@mui/icons-material";
import { message } from "antd";
import { useQueryClient } from "@tanstack/react-query";

const CusListing = ({ viewersData, storyId,setCusModalVisible }) => {
    const [showOptions, setShowOptions] = useState(false);
    const queryClient = useQueryClient();
    const toggleOptionsMenu = () => {
        setShowOptions(prev => !prev);
    };

    const deleteStory = async ()=>{
        
        try{
            await makeRequest.delete(`ssakti/users/story/deleteStory/${storyId}`)
            setCusModalVisible(false);
            message.success("Story deleted successfully");
            setInterval(() => {
                queryClient.invalidateQueries(['getCurrentUserStories'])
            },100)
        }
        catch{
            message.error("Failed to delete story");
            
        }
       
    }
    return (
        <>
            <div className="viewer-main-container">
                <div className="heading">
                    <p>Viewed by</p>
                    <div>
                    <MoreVertRounded className="more-icon" onClick={toggleOptionsMenu} />
                    {showOptions && (
                            <div className="options-menu">
                                <ul>
                                    <li onClick={deleteStory}>Delete</li>
                                    <li>Share</li>
                                </ul>
                            </div>
                        )}
                    </div>
                    
                </div>
                {
                   viewersData.map((viewer) => {
                        return (

                            <>
                                <div key={viewer?.storyId}  className="viewer-container">
                                    <div className="viewer-img">
                                        <img src={viewer.userProfileImagePath} alt="user-image" />
                                    </div>
                                    <div className="viewer-info">
                                        <div className="viewer-name">{`${viewer.userFirstName} ${viewer.userLastName}`}</div>
                                        <div className="viewed-time">{viewer.viewedTime}</div>
                                    </div>
                                </div>
                            </>

                        )
                    })}
                </div>
            </>
            )
};

            export default CusListing;