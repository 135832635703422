import React, { useContext, useState, useEffect } from "react";
import "./saved.scss";
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import { Pagination, notification, Modal } from "antd";
import { Link } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Post from "../../components/post/Post";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

function Saved() {
  const { currentUser } = useContext(AuthContext);
  const [totalElements, setTotalElements] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [savedPosts, setSavedPosts] = useState([]);
  const [hasPages, setHasPages] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [postToShow, setPostToShow] = useState(null);
  const [isLikedModal,setIsLikedModal] = useState(false);
  const queryClient = useQueryClient();

  const getUserPages = async (page = 1, size = 5) => {
    try {
      const response = await makeRequest.get(
        `/ssakti/users/savepost/getSavedPost/${currentUser.id}`,
        { params: { page: page - 1, size } }
      );
      console.log(response);
      setSavedPosts(response.data.savedPostData);
      setTotalElements(response.data.totalElements || 0);
      setHasPages(response.data.savedPostData.length > 0);
    } catch (error) {
      console.error(error);
      setHasPages(false);
    }
  };

  useEffect(() => {
    getUserPages(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const handleDelSavedPost = async (postId) => {
    try {
      const response = await makeRequest.delete(
        `/ssakti/users/savepost/deletSavedPost/${currentUser.id}/${postId}`
      );
      if (response.status === 200) {
        notification.success({ message: response.data.message });
        getUserPages(currentPage, pageSize);
        setIsModalVisible(false);
        queryClient.invalidateQueries(["posts"])
      }
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || "Error deleting post.",
      });
      setIsModalVisible(false);
    }
  };

  const handleLikeToggle = async (postId, isLiked) => {
    const endpoint = isLiked
      ? `/ssakti/users/postreact/deleteReactOnPost/${currentUser.id}/${postId}`
      : `/ssakti/users/postreact/addReactOnPost/${currentUser.id}/${postId}`;
      
    const method = isLiked ? 'delete' : 'post';

    try {
      await makeRequest[method](endpoint, {
        postReactName: "Like",
        postReactImageUrl: "string",
      });
      getUserPages(currentPage, pageSize);
    } catch (error) {
      notification.error({
        message: "Error updating like status.",
      });
    }
  };
  

  return (
    <div className="savedPage">
      {hasPages ? (
        <div>
          <h1>{t("savedPosts")}</h1>
          <div className="userPost">
            {savedPosts && savedPosts.map((post) => {
              const isLiked = post.userReactStatus; // Use the actual status from the post
              return (
                <div className="post" key={post.postId}>
                  <div className="postName">
                    <img src={post.userProfileImageUrl} alt="User" width="40" height="40" />
                    <p>{post.userName}</p>
                  </div>
                  {
                    post.postImageURl == "emptyPostImageUrl" 
                    ?
                    <div style={{height:"200px",padding:"10px"}}>
                      <p style={{fontWeight:"500"}}>{post.postName}</p>
                      </div>
                    :
                  <img
                  src={post?.postType?.includes('video')? post.videoThumbnailUrl : post.postImageURl}
                  alt="Post"
                  width="350"
                  height="200"
                  style={{ objectFit: "cover" }}
                  onClick={() => {
                    setIsModalVisible(true);
                    setPostToShow(post);
                    setIsLikedModal(post.userReactStatus)
                  }}
                  />
                }
                  <div className="actions">
                    {isLiked ? (
                      <FavoriteIcon
                        sx={{ color: "red" }}
                        className="size-4"
                        onClick={() => handleLikeToggle(post.postId, true)}
                      />
                    ) : (
                      <FavoriteBorderIcon onClick={() => handleLikeToggle(post.postId, false)} />
                    )}
                    <BookmarkIcon
                    className="size-4"
                    onClick={() => handleDelSavedPost(post.postId)}
                  />
                  </div>
                </div>
              );
            })}
            <Modal
              visible={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false);
                setPostToShow(null);
              }}
              footer={null}
            >
              <div className="modalContent" style={{display:"flex",flexDirection:"column",gap:"20px"}}>
                <div className="postName" style={{display:'flex',alignItems:"center",gap:'10px'}}>
                  <img src={postToShow?.userProfileImageUrl} alt="User" width="40" height="40" style={{borderRadius:"50%",objectFit:'cover'}} />
                  <p>{postToShow?.userName}</p>
                </div>
                <p style={{fontWeight:"500"}}>{postToShow?.postName}</p>
                {
                  postToShow?.postType !== null && postToShow?.postType.includes("video") ?
                  <video controls style={{height:"70vh"}}>
          <source src={postToShow?.postImageURl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        : 
                  <img
                  src={postToShow?.postImageURl}
                  alt="Post"
                  width="100%"
                  height="300"
                  style={{ objectFit: "cover" }}
                  />
              }
                <div className="actions">
                {/* {isLikedModal ? (
                      <FavoriteIcon
                        sx={{ color: "red" }}
                        className="size-4"
                        onClick={() => handleLikeToggle(postToShow?.postId, true)}
                      />
                    ) : (
                      <FavoriteBorderIcon onClick={() => handleLikeToggle(postToShow?.postId, false)} />
                    )} */}
                  <BookmarkIcon
                    className="size-4"
                    onClick={() => handleDelSavedPost(postToShow?.postId)}
                  />
                </div>
              </div>
            </Modal>
          </div>

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalElements}
            onChange={onPageChange}
            showSizeChanger
            pageSizeOptions={[2, 4, 6, 8, 10]}
          />
        </div>
      ) : (
        <div className="noPages">
          <h1>{t("noSavedPosts")}</h1>
          <button>
          <Link to="/">{t("wantToAddYours")}</Link>
          </button>
        </div>
      )}
    </div>
  );
}

export default Saved;

