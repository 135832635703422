import React from 'react';
import Stories from 'react-insta-stories';
import { makeRequest } from '../../../axios';
import { VideoRenderer, videoTester, ImageRenderer, imageTester } from '../Renderers.js';

const StoriesModal = ({ setOpenStories, selectedStories, currentUser, handleAutoNextStories }) => {
    const isMobile = window.innerWidth < 786;

    const stories = selectedStories.storyDetails.map((storiesList) => {


        return {
            url: storiesList.storyUrl,
            type: storiesList.storyType.includes("video") ? "video" : "image",
            currentUserId: currentUser.id,
            storyId: storiesList.storyId,
            // onStoryStart: (props) => {
            //     // Mark story as viewed
            //     console.log("currentUser.id", currentUser.id)
            //     console.log("storiesList.storyId", currentUser.id)
            //     makeRequest.post(`ssakti/users/story/addViewedStory/${currentUser.id}/${storiesList.storyId}`);
            // },

            header: {
                heading: `${selectedStories.userFirstName} ${selectedStories.userLastName}`,
                subheading: storiesList.storyCreatedAt,
                profileImage: selectedStories.userProfileImagePath,
            },
        };
    });

    return (
        <div className="stories-modal">
            <Stories
                stories={stories}
                defaultInterval={1500}
                width={isMobile ? '100vw' : 423}
                height={isMobile ? '100vh' : 768}
                onAllStoriesEnd={() => handleAutoNextStories()}
                onStoryStart={(props)=>{
                    makeRequest.post(`ssakti/users/story/addViewedStory/${currentUser.id}/${stories[Number(props)]?.storyId}`);
                }}
                storyStyles={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    width: '100%',
                    height: '100%',
                }}
            renderers={[
                {
                    renderer: VideoRenderer,
                    tester: videoTester,
                },
                {
                    renderer: ImageRenderer,
                    tester: imageTester,
                },
            ]}
            />
        </div>
    );
};

export default StoriesModal;
