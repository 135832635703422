import "./userManager.scss";
import { makeRequest } from "../../axios";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { notification } from 'antd';
import moment from 'moment';
import { useState } from "react";
import { Pagination } from 'antd';

const UserManager = () => {
    const queryClient = useQueryClient();
    const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);

    // const { isLoading, isError, data: users } = useQuery(['getUserList'], () => {
    //     return makeRequest.get('ssakti/admin/user/getAllUser').then((res) => {
    //         return res.data.userDetails;
    //     });
    // });

    const fetchUsers = async (page=1, size=6) => {
        const endpoint = `ssakti/admin/user/getAllUserWithPagination`;
        try {
          const response = await makeRequest.get(endpoint, {
            params: { page: page - 1, size },
          });
    
          if (response.status === 204) {
            return {
              pagesDetail: [],
              totalElements: 0,
            };
          }
    console.log(response.data)
          return response.data;
        } catch (error) {
          console.error('Fetch error:', error);
          throw error;
        }
      };

    const { data, isLoading, isError } = useQuery(
        ['getUserList', currentPage, pageSize],
        () => fetchUsers(currentPage, pageSize),
        {
          keepPreviousData: true,
        }
      );

      const onPageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
      };
    

    const mutation = useMutation((id) => {
        return makeRequest.delete("ssakti/admin/user/deleteUser/" + id);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries('getUserList');
            notification.success({
                message: 'User Deleted Successfully',
                description: 'All the posts and comments have been deleted',
            });
        },
        onError: () => {
            notification.error({
                message: 'Deletion Failed',
                description: 'Failed to delete user. Please try again.',
            });
        },
    });

    const deleteUser = (userId) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            mutation.mutate(userId);
        }
    };

    if (isError) {
        return <p>Error fetching users</p>;
    }

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="adminUserManager">
            <h2>User Manager</h2>
            <div className="user-list">
                <div className="heading-container">
                    <div className="image-heading"><h3>User Pic</h3></div>
                    <div className="info-heading">
                        <h3 className="info-heading-common">Name</h3>
                        <h3 className="info-heading-common">Email</h3>
                        <h3 className="info-heading-common">Total Posts</h3>
                        <h3 className="info-heading-common">Created At</h3>
                    </div>
                    <div className="button-heading"><h3>Action</h3></div>
                </div>

                {data?.userDetails.map(user => (
                    <div key={user.id} className="user-card">
                        <div className="image-container">
                            <img src={user.userProfileImagePath} alt={`${user.userFirstName} ${user.userLastName}`} />
                        </div>
                        <div className="user-info">
                            <div className="name-container info-container-common"><h4>{user.userFirstName + " " + user.userLastName}</h4></div> 
                            <div className="email-container info-container-common"><p>{user.userEmail}</p></div>
                            <div className="totalPost-container info-container-common"><p>{user.totalPostCount}</p></div>
                            <div className="date-container info-container-common"><p>{moment(user.userCreatedAt).format('LL')}</p></div>
                        </div>
                        <div className="button-container">
                            <button aria-label={`Delete user ${user.userFirstName}`} onClick={() => deleteUser(user.userId)}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>
            <div>
          <Pagination
            showSizeChanger
            onChange={onPageChange}
            current={currentPage}
            pageSize={pageSize}
            total={data?.totalElements || 0}
            pageSizeOptions={[4, 8, 12, 16, 32, 64]}
          />
          </div>
        </div>
    );
};

export default UserManager;
