import { useState } from "react";
import { makeRequest } from "../../axios";
import "./update.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AWS from 'aws-sdk';
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { notification } from 'antd';

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Update = ({ setOpenUpdate, user }) => {
  const [cover, setCover] = useState(null);
  const [profile, setProfile] = useState(null);
  const [updatePassword, setUpdatePassword] = useState(false); // Toggle for updating password
  const [texts, setTexts] = useState({
    firstName: user.userFirstName,
    lastName: user.userLastName,
    dob: user.userBirthDate,
    address: user.userLocation,
    password: '', // Password initially empty
  });

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const S3_BUCKET = 'streesakti';

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const s3 = new AWS.S3();

  const handleChange = (e) => {
    setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (user) => {
      return makeRequest.put(`ssakti/users/user/updateUser/${currentUser.id}`, user);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        setCurrentUser((prev) => {
          return { ...prev, profilePic: variables.userProfileImagePath };
        });
        notification.success({
          message: 'Profile updated successfully',
        });
      },
    }
  );

  const handleClick = async (e) => {
    e.preventDefault();

    // Validation for required fields
    if (!texts.firstName || !texts.lastName || !texts.dob || !texts.address) {
      notification.error({
        message: 'Error',
        description: 'First Name, Last Name, Date of Birth, and Address are required!',
      });
      return; // Exit if validation fails
    }

    let coverUrl;
    let profileUrl;

    if (cover) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${cover.name}`,
        Body: cover,
      };
      try {
        const data = await s3.upload(params).promise();
        coverUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      coverUrl = user.userCoverProfileImage ? user.userCoverProfileImage : "";
    }

    if (profile) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${profile.name}`,
        Body: profile,
      };
      try {
        const data = await s3.upload(params).promise();
        profileUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      profileUrl = user.userProfileImage ? user.userProfileImage : "";
    }

    // Prepare the payload
    const payload = {
      userFirstName: texts.firstName,
      userLastName: texts.lastName,
      userDateOfBirth: texts.dob,
      userAddress: texts.address,
      userProfileImagePath: profileUrl,
      userCoverProfileImagePath: coverUrl,
    };

    // Add password to payload only if updating
    if (updatePassword) {
      payload.userPassword = texts.password;
    }

    mutation.mutate(payload);

    setOpenUpdate(false);
    setCover(null);
    setProfile(null);
  };

  return (
    <div className="update">
      <div className="wrapper">
        <h1>Update Your Profile</h1>
        <form>
          <div className="files">
            <label htmlFor="cover">
              <span>Cover Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    cover
                      ? URL.createObjectURL(cover)
                      : user.userCoverProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="cover"
              style={{ display: "none" }}
              onChange={(e) => setCover(e.target.files[0])}
            />
            <label htmlFor="profile">
              <span>Profile Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    profile
                      ? URL.createObjectURL(profile)
                      : user.userProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="profile"
              style={{ display: "none" }}
              onChange={(e) => setProfile(e.target.files[0])}
            />
          </div>

          {/* First Name Input */}
          <div className="input-wrapper">
          <div>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={texts.firstName}
              onChange={handleChange}
              placeholder="Enter first name"
              required
            />
          </div>

          {/* Last Name Input */}
          <div>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={texts.lastName}
              onChange={handleChange}
              placeholder="Enter last name"
              required
            />
          </div>
          </div>

          {/* Date of Birth Input */}
          <div className="input-wrapper">
          <div>
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              value={texts.dob}
              onChange={handleChange}
              required
            />
          </div>

          {/* Address Input */}
          <div>
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={texts.address}
              onChange={handleChange}
              required
            />
          </div>
          </div>

          {/* Password Toggle */}
          <div className="input-wrapper">
          <div>
            <label id="password-ag">
              <input
                type="checkbox"
                checked={updatePassword}
                onChange={() => setUpdatePassword(!updatePassword)}
              />
              Do you want to update your password?
            </label>
          </div>

          {/* Password Input (visible only if updatePassword is true) */}
          {updatePassword && (
            <div>
              <label>Password</label>
              <input
                type="password"
                value={texts.password}
                name="password"
                onChange={handleChange}
                placeholder="Enter new password"
              />
            </div>
          )}
          </div>

          <button onClick={handleClick}>Update</button>
        </form>
        <button className="close" onClick={() => setOpenUpdate(false)}>
          Close
        </button>
      </div>
    </div>
  );
}

export default Update;
